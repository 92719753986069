import React from 'react'

function Index() {
    return (
        <div className='max-w-7xl mx-auto py-2'>
            <p>vickreyalex@gmail.com </p>
        </div>
    )
}

export default Index
